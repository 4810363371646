type RegistrationType = 'form' | 'complete';

const INIT_FOCUS_ITEMS: string[] = [];
const INIT_REGISTRATION_TYPE = 'form';

type State = {
  focusItems: string[];
  jobQuery: string;
  registrationType: RegistrationType;
};

export const useRegistrationStore = defineStore('registration', {
  state: (): State => ({
    focusItems: INIT_FOCUS_ITEMS,
    jobQuery: '',
    registrationType: INIT_REGISTRATION_TYPE,
  }),
  actions: {
    setFocusItems(value: string[]) {
      this.focusItems = value;
    },
    setJobQuery(value: string) {
      this.jobQuery = value;
    },
    setRegistrationType(value: RegistrationType) {
      this.registrationType = value;
    },
  },
  persist: {
    storage: persistedState.localStorage,
    key: 'registration',
  },
});
